@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Istok+Web:wght@400;700&family=Akaya+Telivigala&display=swap');

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
}

.font-akaya {
  font-family: 'Akaya Telivigala', cursive;
}
.font-istok-web {
  font-family: 'Istok Web', sans-serif !important;
}
.font-inter {
  font-family: 'Inter', sans-serif !important;
}

/* product-clip-path */
.product-clip-path {
  clip-path: polygon(98% 0, 91% 100%, 0 100%, 0 0);
}
/* product list slider */
.product-list-slider {
  fill: rgba(217, 217, 217, 0.3);
  mix-blend-mode: color-dodge;
  box-shadow: 0px 0px 3.857973337173462px 0px #ffee93 inset;
  filter: drop-shadow(0px 0px 3.857973337173462px rgba(255, 255, 255, 0.35));
}
.product-detail-title{
  @apply text-[22px] leading-10 text-pale-yellow font-medium
}

.slick-button-before:before {
  display: none !important;
}
/* button transition */
.btn-transition {
  /* light animation effect */
  transition: all 0.2s ease-in-out;
}

/* date picker */
.react-datepicker {
  font-size: 0.8rem;
  color: #000;
  border: none !important;
  display: inline-block;
  position: relative;
}
.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: transparent !important;
  border-bottom: none !important;
  padding: 8px 0;
  position: relative;
}

/* tables */

.custom-table {
  border-collapse: separate;
  border-spacing: 0 11px;
}

/* .sales-row:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 0.696072518825531px 5px 0px rgba(104, 135, 154, 0.5);
}
.sales-row:hover td {
  border: 2px solid #005487;
  border-style: solid none solid none;
}

.custom-table td:first-child {
  border-style: solid none solid none;
}
.custom-table td:last-child {
  border-style: solid solid solid none;
}

.custom-table tr:first-child td {
  border-top-style: solid;
}
.custom-table tr td:first-child {
  border-left-style: solid;
} */

.custom-table tr,
.custom-table td:first-child {
  border-top-left-radius: 10px;
}

.custom-table tr,
.custom-table td:last-child {
  border-top-right-radius: 10px;
}

.custom-table tr,
.custom-table td:first-child {
  border-bottom-left-radius: 10px;
}

.custom-table tr,
.custom-table td:last-child {
  border-bottom-right-radius: 10px;
}

.dropdown-row {
  position: relative;
  top: -20px;
}

.sales-table .dropdown-row {
  position: relative;
  top: -10px;
}

.custom-table .dropdown-row,
.custom-table .dropdown-row td:first-child {
  border-top-left-radius: 0px;
}

.custom-table .dropdown-row,
.custom-table .dropdown-row td:last-child {
  border-top-right-radius: 0px;
}

/* width */
.yellowScroll::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.yellowScroll::-webkit-scrollbar-track {
  background: #b3b4b4;
  border-radius: 5px;
}

/* Handle */
.yellowScroll::-webkit-scrollbar-thumb {
  background: #ffd600;
  border-radius: 5px;
}

/* Handle on hover */
.yellowScroll::-webkit-scrollbar-thumb:hover {
  background: #ffd600;
}

/*Fire Fox */

.yellowScroll {
  /* scrollbar-width: thin;
  scrollbar-color: #ffd600 #1e1e1e; */
  scrollbar-gutter: stable;
}

/* width */
.yellowScrollNoBg::-webkit-scrollbar {
  width: 7px;
  background: #c6c6c659;
}

/* Track */
.yellowScrollNoBg::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

/* Handle */
.yellowScrollNoBg::-webkit-scrollbar-thumb {
  background: #ffd600;
  border-radius: 5px;
}

/* Handle on hover */
.yellowScrollNoBg::-webkit-scrollbar-thumb:hover {
  background: #ffd600;
}

/*Fire Fox */

.yellowScrollNoBg {
  scrollbar-width: thin;
  scrollbar-color: #ffd600 transparent;
  scrollbar-gutter: stable;
}

/* horizontal scroll */
.yellowScrollHorizontal::-webkit-scrollbar {
  height: 5px;
}

/* width */
.grayScrollNoBg::-webkit-scrollbar {
  width: 7px;
  background: transparent;
}

/* Track */
.grayScrollNoBg::-webkit-scrollbar-track {
  background: transparent;
  margin: 15px 0;
  border-radius: 4px;
}

/* Handle */
.grayScrollNoBg::-webkit-scrollbar-thumb {
  background: #b3b4b4;
  border-radius: 5px;
}

/* Handle on hover */
.grayScrollNoBg::-webkit-scrollbar-thumb:hover {
  background: #b3b4b4;
}

/*Fire Fox */

.grayScrollNoBg {
  scrollbar-width: thin;
  scrollbar-color: #b3b4b4 transparent;
  scrollbar-gutter: stable;
}

.styledDiv {
  background: transparent;
  position: relative;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
}

.styledDiv::after {
  border: 1px solid white;
  border-right: 0;
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  transform: rotate(180deg);
  width: 100%;
  height: calc(100% + 1px);
  transform-origin: top right;
  transform: skew(170deg, 0deg);
}

.scroll-left {
  direction: rtl;
}

.direction-ltr {
  direction: ltr;
}

/* graphs */
.line-graph .apexcharts-tooltip-title {
  color: black;
}

.line-graph .apexcharts-tooltip-text-y-label {
  color: black !important;
}

.line-graph .apexcharts-tooltip-text-y-value {
  color: black !important;
}

.formGrid {
  grid-template-columns: max-content 1fr;
}

/* scroll right */
.scroll-right {
  direction: rtl;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #ffd600 transparent;
  scrollbar-gutter: stable;
}
.scroll-right::-webkit-scrollbar {
  width: 7px;
  background: #c6c6c640;
}
.scroll-right::-webkit-scrollbar-track {
  /* background: transparent; */
  border-radius: 5px;
}
.scroll-right::-webkit-scrollbar-thumb {
  background: #ffd600;
  border-radius: 7px;
}
.direction-ltr {
  direction: ltr;
}

.box-shadow {
  box-shadow: 0px 0.696072518825531px 9.745015144348145px 0px rgba(0, 0, 0, 0.15);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
/* remove chrome autocomplete background color */
.input-no-bg:-webkit-autofill:-webkit-autofill,
.input-no-bg:-webkit-autofill:-webkit-autofill:hover,
.input-no-bg:-webkit-autofill:-webkit-autofill:focus,
.input-no-bg:-webkit-autofill:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: black;
}
input:focus,textarea:focus{
  outline: none;
}

.css-148sd3j-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: transparent !important;
}

.account-information-clip-path {
  clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('/public/images/company-registration/dropdown-icon.svg');
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 12px;
}

.shrink{
  transform: scale(0.9);
  transform-origin: 0 0;
}
.text-field {
  border: none;
  outline: none;
}
.text-field:focus {
  border: none;
  outline: none;
}
.text-field::placeholder {
  color: #888;
  opacity: 1; /* Firefox */
}
.text-field::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #888;
}

/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: '';
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.upload-doc-text {
  span {
    color: #fd1515;
  }
}

.input-unset {
  all: unset;
}

.rowTest{
  display:grid;
  grid-template-columns: repeat(6, 1fr);
  
}
.expanded-row-content {
  display: grid;
  grid-column: 1/-1;
}
.parse-content p{
  text-indent: 0;
}